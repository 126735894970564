<script setup lang="ts">
import { TabGroup, TabList, TabPanels, TabPanel, Tab } from '@headlessui/vue';
import {
  useAsyncData,
  useFlightService,
  useI18n,
  useConfigStore,
  useNavigation,
  useTracker,
  normalizeSlug,
} from '#imports';

const { locale } = useI18n();
const config = useConfigStore();
const { navigationPath } = useNavigation();
const { jitsuEvent } = useTracker();

type Flight = {
  code: string;
  name: string;
  city: string;
  cityEN?: string;
  nameEN?: string;
};

type PopularRoute = {
  arrAirport: string;
  arrCity: string;
  arrCityEN: string;
  depAirport: string;
  depCity: string;
  depCityEN: string;
};

const { data: flights, execute: fetchFlights } = await useAsyncData(
  () => useFlightService().getPopularFlights({ destination: '', lang: locale.value, country: config.country! }),
  {
    transform: (data) => data.result.routes.slice(0, 15),
  },
);
const { data: routes, execute: fetchRoutes } = await useAsyncData(
  () => useFlightService().getPopularRoutes({ lang: locale.value, country: config.country! }),
  {
    transform: (data) => data.result,
  },
);

const container = ref(null);
const isContainerVisible = useElementVisibility(container);

const getFlightPath = (flight: { code: string; cityEN: string }) =>
  navigationPath('/flight/to/' + normalizeSlug(flight.cityEN + '-' + flight.code, true));

const getRoutePath = (route: { depAirport: string; depCityEN: string; arrAirport: string; arrCityEN: string }) =>
  navigationPath(
    '/flight-tickets/' +
      normalizeSlug(`${route.depCityEN}-${route.depAirport}-${route.arrCityEN}-${route.arrAirport}`, true),
  );

const onClickPopularFlight = (flight: Flight) => {
  jitsuEvent('user-click-button', {
    event_name: 'popularflight-click',
    product: 'flight',
    object_name: 'popular-footer',
    object_parameter: JSON.stringify({
      flights: flights.value!.join(','),
    }),
    city_arrival: flight.code,
  });
};

const onClickPopularRoute = (popularRoute: PopularRoute) => {
  jitsuEvent('user-click-button', {
    event_name: 'popularroutes-click',
    product: 'flight',
    object_name: 'popular-footer',
    object_parameter: JSON.stringify({
      routes: routes.value!.join(','),
    }),
    city_arrival: `${popularRoute.arrAirport}`,
    popular_routes: `${popularRoute.depAirport}${popularRoute.arrAirport}`,
  });
};

watchOnce(isContainerVisible, () => {
  fetchFlights();
  fetchRoutes();
});
</script>

<template>
  <div ref="container">
    <h2 class="text-center text-extra font-bold mb-20">
      {{ $t('global.populartitle') }}
    </h2>
    <div class="text-center text-medium text-gray-dark mb-20">
      {{ $t('global.popularsubtitle') }}
    </div>

    <TabGroup>
      <TabList class="flex justify-evenly border-b-[0.5px] border-gray-lightest mb-40">
        <Tab
          v-slot="{ selected }"
          as="template"
        >
          <button
            class="relative text-medium font-semibold text-gray-light p-20 outline-none"
            data-testid="top-popularFlight-button"
            :class="{
              'text-primary after:block after:absolute after:bottom-0 after:left-0 after:w-full after:h-[2px] after:bg-primary':
                selected,
            }"
          >
            <h3>
              {{ $t('global.toppopularflight') }}
            </h3>
          </button>
        </Tab>
        <Tab
          v-slot="{ selected }"
          as="template"
        >
          <button
            class="relative text-medium font-semibold text-gray-light p-20 outline-none"
            data-testid="top-popularRoutes-button"
            :class="{
              'text-primary after:block after:absolute after:bottom-0 after:left-0 after:w-full after:h-[2px] after:bg-primary':
                selected,
            }"
          >
            <h3>
              {{ $t('global.toppopularroute') }}
            </h3>
          </button>
        </Tab>
      </TabList>

      <TabPanels class="pl-[150px]">
        <TabPanel class="grid grid-cols-3 gap-20">
          <NuxtLink
            v-for="flight in flights"
            :key="flight.code"
            :to="getFlightPath(flight)"
            class="text-base underline-offset-4 hover:underline"
            data-testid="popular-flight-text"
            external
            no-rel
            @click="onClickPopularFlight(flight)"
          >
            {{ $t('route.tocity', [flight.city]) }}
          </NuxtLink>
        </TabPanel>

        <TabPanel
          class="grid grid-cols-3 gap-20"
          :unmount="false"
        >
          <NuxtLink
            v-for="route in routes"
            :key="`${route.depAirport}_${route.arrAirport}`"
            :to="getRoutePath(route)"
            class="text-base underline-offset-4 hover:underline"
            data-testid="popular-routes-text"
            external
            no-rel
            @click="onClickPopularRoute(route)"
          >
            {{ $t('route.textpoproute', [route.depCity, route.arrCity]) }}
          </NuxtLink>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>
